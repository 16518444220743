<template>
  <div>
    <v-dialog
      v-model="show"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline"
          primary-title
        >
          {{ requiredAction.headline }}
        </v-card-title>
        <v-alert
          :value="true"
          type="info"
        >
          {{ requiredAction.alert }}
        </v-alert>
        <v-card-text>
          <v-form ref="requiredActionForm">
            <v-container grid-list-xs>
              <v-layout
                row
                wrap
              >
                <v-flex
                  v-for="(field, i) in requiredAction.fields"
                  :key="i"
                  xs12
                >
                  <v-textarea
                    v-if="field.component === 'textarea'"
                    v-model="field.value"
                    v-bind="bind(field)"
                  />
                  <v-text-field
                    v-if="field.component === 'text-field'"
                    v-model="field.value"
                    v-bind="bind(field)"
                  />
                  <v-select
                    v-if="field.component === 'select'"
                    v-model="field.value"
                    v-bind="bind(field)"
                  />
                  <v-menu
                    v-if="field.component === 'date'"
                    v-model="field.menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-model="field.value"
                        v-bind="bind(field)"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="field.value"
                      no-title
                      @input="field.menu = false"
                    />
                  </v-menu>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-alert
          :value="alert"
          type="error"
          transition="scale-transition"
        >
          {{ alertMessage }}
        </v-alert>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            flat
            @click="postRequiredAction()"
          >
            {{ requiredAction.submitText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="status"
      width="400"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline"
          primary-title
          v-html="statusMessage.title"
        />
        <v-alert
          :value="true"
          :color="statusMessage.color"
          v-html="statusMessage.body"
        />
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            flat
            @click="status = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { createPostParams } from '@/rest'

export default {
  props: {
    value: {
      required: true,
      type: Boolean,
    },
    requiredAction: {
      required: true,
      type: Object,
    },
  },
  data () {
    return {
      alert: false,
      alertMessage: null,
      payload: {},
      status: false,
      statusMessage: {
        color: 'orange',
        title: 'Status',
        body: 'Thank you for taking the time to update the information.',
      },
    }
  },
  computed: {
    show: function () {
      return this.value
    },
  },
  watch: {
    requiredAction: {
      handler: function (action) {
        let index = 0
        let watchedValue = null

        action.fields.forEach(field => {
          if (field.component === 'select' && field.watch) {
            action.fields.forEach(watched => {
              if (watched.name === field.watch) {
                if (!field.watchedValue) {
                  field.watchedValue = null
                }
                if (field.watchedValue !== watched.value) {
                  field.value = null
                  watchedValue = field.watchedValue = watched.value
                }
              }
            })
          }
          !watchedValue && index++
        })

        if (watchedValue) {
          this.requiredAction.fields[index].bind.items = this.requiredAction.fields[index].watchedItems[watchedValue]
        }
      },
      deep: true,
    },
  },
  methods: {
    async postRequiredAction () {
      if (!this.$refs.requiredActionForm.validate()) return
      try {
        this.requiredAction.fields.forEach(field => {
          this.payload[field.name] = field.value
        })

        const params = createPostParams({
          action: this.requiredAction.action,
          payload: this.payload,
        })
        const response = await this.$rest.post('postAction.php', params)
        if (response.data) {
          if (response.data.color) {
            this.statusMessage.color = response.data.color
          }
          if (response.data.title) {
            this.statusMessage.title = response.data.title
          }
          if (response.data.body) {
            this.statusMessage.body = response.data.body
          }
        }

        this.status = true
        this.$emit('input', false)
      } catch (error) {
        let message = error.message
        if (error.response) {
          message += ': ' + error.response.data
        }
        this.alertMessage = message
        this.alert = true
      }
    },
    bind: function (field) {
      const bind = field.bind

      if (field.rules && field.rules.length > 0) {
        const rules = []
        if (field.rules) {
          /* eslint-disable no-eval */
          field.rules.forEach(rule => rules.push(eval(rule)))
          Object.assign(bind, { rules })
        }
      }

      return bind
    },
  },
}
</script>
